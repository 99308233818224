<template>
  <b-dropdown block no-caret id="dropdown-options-sales" variant="outline-gray-light" menu-class="w-100" :disabled="chat.casting_call && Object.keys(chat.casting_call).length > 0 || chat.is_brandme_talent">
    <template #button-content>
      <div class="d-flex justify-content-center avenir-black">
        <div class="text-flow">
          {{$t('creator.options_select').find((option) => option.value === option_selected_dropdown).text}}
          <span v-if="chat.proposal">{{ chat.proposal.user.first_name }} {{ chat.proposal.user.last_name }}</span>
          <feather-icon icon="ChevronDownIcon"></feather-icon>
        </div>
      </div>
    </template>
    <div v-if="!chat.is_saled && chat.proposal">
      <b-dropdown-item v-for="proposal in proposals_user" :key="proposal.uuid" @click="$emit('change_proposal', proposal)">
        <div class="text-flow avenir-medium">
          {{ chat.proposal.user.first_name }}: {{ proposal.title }}
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click="$emit('change_dropdown', option.value)" v-if="proposals_user.length === 0" disabled>
        <div class="text-flow avenir-medium">{{ $t('creator.noResultsPublics') }}</div>
      </b-dropdown-item>
    </div>
    <div v-else>
      <b-dropdown-item @click="$emit('change_dropdown', option.value)" v-for="option in options_select" :key="option.value" class="avenir-medium">{{option.text}}</b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';

export default {
  name: 'dropdownChatStates',
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
    option_selected_dropdown: {
      type: String,
      default: ''
    },
    proposals_user: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      options_select: [
        {value: 'order_invoice', text: 'Factura de pedido'},
        {value: 'order_status', text: 'Estado de pedido'},
        {value: 'proposal_detail', text: 'Detalle de propuesta'}
      ],
    }
  },
}
</script>

<style>
#dropdown-options-sales .dropdown-menu {
  transform: translate3d(0px, 57px, 0px) !important;
}
#dropdown-options-sales .btn {
  padding: 0;
}
#dropdown-options-sales, #dropdown-options-sales-sidebar {
  flex: auto;
}
#dropdown-options-sales .btn, #dropdown-options-sales-sidebar .btn{
  height: 100%;
}
</style>
<style scoped lang="scss">
.text-flow {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
